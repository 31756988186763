<template>
    <div style="height:100%;">
        <a-layout-content style="height:100%;">
            <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;width:1500px">
                <a-col :span="24">
                    <label class="yj-form_label">排序 :</label>
                    <a-radio-group :default-value="ordervalue" @change="radioChange">
                        <a-radio-button value="0">按推送时间</a-radio-button>
                        <a-radio-button value="1">拉新最多</a-radio-button>
                        <a-radio-button value="2">阅读最多</a-radio-button>
                    </a-radio-group>
                    <div style="float:right">
                        <a-input id="name"
                                 contextmenu="2455"
                                 :value="name"
                                 style="width:260px"
                                 @change="name_change"
                                 placeholder="输入关键词搜索"></a-input>
                        <a-button type="primary" @click="search">查询</a-button>
                    </div>
                </a-col>
                <template v-for="info in tableData">
                    <a-card hoverable style="width: 440px;float:left;height:250px; margin:30px" :key="info.ID" @click="EditRow(info)">
                        <img slot="cover" :src="Getsrc(info)" style="height:150px" />
                        <a-card-meta :title="info.Material.Name">
                            <template slot="description">
                                领取:{{info.ReceiveCount }} 阅读:{{info.ReadCount }} 拉新:{{info.PullNewCount }}
                                <br />
                                {{TimeStamptoDateTime(info.InputTime)}}
                            </template>
                        </a-card-meta>
                    </a-card>
                </template>
                <div style="float:left; text-align:center;width:100%">
                    <a-pagination v-model="current" :total="total" :defaultPageSize="defaultPageSize" :showTotal="showTotal" @change="pagerChange" />
                </div>
            </div>
        </a-layout-content>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "PushList",
        data() {
            return {
                ordervalue: "0",
                orderName: "InputTime",
                name: "",
                tableData: [],
                current: 1,
                total: 0,
                defaultPageSize: 9,
                showTotal: total => `共 ${total} 条数据`
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            name_change: function (item) {
                var self = this;
                self.name = item.target.value;
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetMaterialCustomerList",
                    data: {
                        pageIndex: self.current,
                        PageCount: 9,
                        PackageID: 1,
                        MaterialType: -1,
                        PublishStatus: -1,
                        Name: self.name,
                        OrderName: self.orderName,
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.total = data.data.Total;
                        self.defaultPageSize = data.data.RowCount;
                        self.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            pagerChange: function (item) {
                var self = this;
                self.current = item;
                self.getTableData();
            },
            search: function () {
                var self = this;
                self.current = 1;
                self.getTableData();
            },
            Getsrc: function (info) {
                var rt = info.Material.Content.ImgTopic == null ? info.Material.Logo : info.Material.Content.ImgTopic;
                return rt.replace("upload", "upload2");
            },
            TimeStamptoDateTime: function (e) {
                return util.TimeStamptoDateTime(e, 'yyyy-MM-dd hh:mm:ss');
            },
            EditRow(e) {
                var id = e.MaterialID;
                var type = e.Material.MaterialType;
                if (type == 0) {
                    this.$router.push({
                        name: "NewMaterial_MomentDetail",
                        query: { id: id, disabled: e.Material.PublishStatus, QueueID: 0 }
                    });
                }
                if (type == 1) {
                    this.$router.push({
                        name: "NewMaterial_ExtensionDetail",
                        query: { id: id, disabled: e.Material.PublishStatus, QueueID: 0 }
                    });
                }
            },
            radioChange: function (item) {
                var self = this;
                self.ordervalue = item.target.value;
                switch (self.ordervalue) {
                    case '0':
                        self.orderName = "InputTime";
                        break;
                    case '1':
                        self.orderName = "PullNewCount";
                        break;
                    case '2':
                        self.orderName = "ReadCount";
                        break;
                }
                self.current = 1;
                self.getTableData();
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.getTableData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>